<template>
  <div class="main-container">
    <div class="emp-container">
      <div class="main-header">
        <div class="l-header">
          <h1>Add Employee To "{{ title.name }}"</h1>
        </div>
        <div class="r-header">
          <div class="btn-emp-update" v-if="selectedEmployees.length>0">
            <v-btn class="btn-emp-update" @click="saveChange">
              Save
            </v-btn>
          </div>
        </div>
      </div>


      <div class="main-section-emp">
        <div class="main-left">
          <v-form class="form-autocomplete">
            <v-row>
              <v-col cols="12" md="12">
                <v-autocomplete
                    v-model="data.employee"
                    filled
                    chips
                    color="blue-grey lighten-2"
                    label="Select Employee"
                    item-text="name"
                    item-value="id"
                    multiple
                    outlined
                    :items="allEmployee"
                    hide-selected
                >
                  <template v-slot:selection="data">
                    <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="removeEmployeeFromTable(data.item)"
                    >
                      <v-avatar left>
                        <v-img :src="data.item.urlProfile"></v-img>
                      </v-avatar>
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content v-text="data.item"></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-avatar>
                        <img :src="data.item.urlProfile" alt="">
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        <v-list-item-subtitle v-html="data.item.surname"></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </div>

        <div class="main-right" v-if="selectedEmployees.length > 0">
          <div class="show-list-emp-position">
            <table class="table table-view">
              <tbody>
              <tr v-for="(item,idx) in selectedEmployees" :key="idx" class="text-start">
                <td>{{ idx + 1 }}</td>
                <td class="image-circle-emp-position">
                  <img
                      :src="item.urlProfile"
                      alt=""/>
                </td>
                <td class="employee-name">
                  <span>{{ item.name }}</span><span>{{ item.surname }}</span>
                </td>
                <td>

                </td>
                <td>

                </td>
                <td class="text-end">
                  <v-menu transition="slide-y-transition"
                          bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          @click="removeEmployeeFromTable(item)"
                          outlined
                          v-bind="attrs"
                          v-on="on"
                      >
                        <i style="font-size: 22px" class="fas fa-minus-circle"></i>
                      </v-btn>
                    </template>
                  </v-menu>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdPositionEmployee",
  props: {
    title: {},
  },
  data() {
    return {
      allEmployee: [],
      allNewEmployeePosition: [],
      data: {
        employee: [],
      },
      pathImage: process.env.VUE_APP_PATH_IMAGE,
    }
  },
  computed: {
    selectedEmployees() {
      return this.data.employee.map((id) => {
        const find = this.allEmployee.filter((item) => {
          return id === item.id;
        })
        if (find.length) {
          return find[0];
        }
        return {};
      })
    }
  },
  methods: {


    fetchAllEmployee() {
      this.$axios.get(`company/all-employee`).then((res) => {
        this.allEmployee = res.data.data;
      }).catch(() => {
      })
    },

    // employeeChange(val) {
    //   const newAddedId = val[val.length - 1];
    //   const find = this.allEmployee.filter((item) => {
    //     return newAddedId == item.id;
    //   })
    //   if (find.length) {
    //     this.allNewEmployeePosition.push(find[0])
    //   }
    // },

    removeEmployeeFromTable(item) {
      const index = this.data.employee.indexOf(item.id)
      if (index >= 0) {
        this.data.employee.splice(index, 1)
      }
    },


    saveChange() {
      const id = this.$route.params.position_id;
      const results = this.selectedEmployees.map((item) => {
        return item.id;
      })
      this.$axios.post(`company/position/${id}/employees`, {employee_id: results}).then((res) => {
        if (res.data.code === 200) {
          setTimeout(() => {
            this.$emit('close');
            this.$emit('success');
            this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
          }, 300)
        }
      })
    }
  },

  created() {
    this.fetchAllEmployee();
  }

}

</script>

<style scoped lang="scss">
.main-container {
  width: 100%;
  height: 100%;
  padding: 50px 100px;

  .form-autocomplete {
    margin-top: 20px;
  }

  .image-circle-emp-position img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
  }

  .main-header {
    width: 100%;
    height: 80px;
    display: flex;

    .l-header {
      width: 50%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      h1 {
        text-transform: capitalize;
        font-size: 28px;
        font-weight: 500;
        font-family: $font-family;
      }
    }

    .r-header {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .btn-emp-update {
        border: none;
        outline: none;
        height: 40px;
        transition: all .125s ease-in;
        background-color: $main-btn-color;
        color: #FFFFFF;
        text-transform: capitalize;
        box-shadow: 0 2px 4px 1px rgb(0 0 0 / 6%);
        border-radius: 4px;
      }
    }
  }

  .main-section-emp {
    display: flex;
    width: 100%;
    height: 100%;

    .main-left {
      width: 50%;
      height: auto;
    }

    .main-right {
      width: 50%;
      height: auto;
      padding-left: 20px;
      padding-top: 10px;
    }
  }
}

.employee-name {
  font-size: 16px;
  font-family: $font-family;
  font-weight: bold;
  color: rgb(0, 0, 0, .8);

  span {
    margin: 0 2px;
  }
}

.table.table-view tbody tr td:first-child {
  padding: 8px;
}
</style>
