<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header" v-for="(item,idx) in filterOldPosition" :key="idx">
        <h1 class="OldPosition">{{ item.name }} <span
            class="CountEmployee">({{ allPositionEmployees.length }} employee)</span></h1>
      </div>
    </div>

    <div class="section-action">
      <div class="search-action">
        <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense prepend-inner-icon="mdi-magnify"
                      placeholder="Search"
        >
        </v-text-field>
      </div>
      <!--      <div class="btn-new-create-action btn-show-action-selected" v-for="(item,idx) in allPositionEmployees" :key="idx">-->
      <!--        <v-btn class="btn-remove" @click="deactive" :loading="btnLoading" v-show="item.active === 1">-->
      <!--          deactive-->
      <!--        </v-btn>-->

      <!--        <v-btn v-show="item.active === 0" class="btn-active" @click="active" :loading="btnLoading">-->
      <!--          Active-->
      <!--        </v-btn>-->
      <!--      </div>-->


      <div class="btn-new-create-action">
        <v-btn class="btn-create" @click="onCreate()">
          AddEmployee
        </v-btn>
      </div>
    </div>

    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view" v-if="allPositionEmployees.length >0">
          <thead>
          <tr>

            <!--            <th class="text-left" style="padding:10px 0;">-->
            <!--              <input type="checkbox" class="checkbox" v-model="selectAll" @click="select">-->
            <!--            </th>-->

            <th class="text-left">
              Image
            </th>
            <th class="text-left">
              ID
            </th>
            <th class="text-left">
              Name/surname
            </th>
            <th class="text-left">
              Email
            </th>
            <th class="text-left">
              Status
            </th>
            <th class="text-left">

            </th>


          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,idx) in allPositionEmployees" :key="idx">
            <!--            <td class="text-start">-->
            <!--              <label class="form-checkbox checkbox">-->
            <!--                <input type="checkbox" :value="item.id" v-model="selected">-->
            <!--                <i class="form-icon"></i>-->
            <!--              </label>-->
            <!--            </td>-->

            <td>
              <div class="image-table" v-if="item.urlProfile">
                <img :src="item.urlProfile" alt="">
              </div>
              <div v-else>
                <div class="demo-image">
                  <i class="fal fa-user-alt"></i>
                </div>
              </div>
            </td>
            <td>{{ item.joinUser.user_card_id }}</td>
            <td>
              <span>{{ item.name }}</span><span>{{ item.surname }}</span>
            </td>

            <td>{{ item.joinUser.email }}</td>
            <td>
              <div>
                <span class="deactive-position" v-if="item.active === 0"><i
                    class="fas fa-circle"></i>Deactive</span>
                <span class="active-position" v-else>
                  <i class="fas fa-circle"></i>Active
                </span>
              </div>
            </td>


            <td class="text-end">
              <v-menu transition="slide-y-transition"
                      bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      outlined
                      v-bind="attrs"
                      v-on="on"
                  >
                    <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-switch style="text-transform: uppercase"
                              inset
                              v-model="item.active"
                              @change="changeStatus(item)"
                    >
                    </v-switch>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
          </tbody>
        </table>
        <defaultTableNoResult v-else/>

        <Loading v-if="isLoading"/>
        <div v-for="(item,idx) in filterOldPosition" :key="idx">
          <ModalAdd fullscreen :title="item.name">
            <template v-slot="{close}">
              <add-position-employees @close="close" :title="item" @success="fetchAllPositionEmployee"/>
            </template>
          </ModalAdd>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import addPositionEmployees from "./AddPositionEmployee";
import Loading from "../../Loading";
import defaultTableNoResult from "../../defaultTableNoResult";

export default {
  components: {
    addPositionEmployees,
    Loading,
    defaultTableNoResult
  },
  data() {
    return {
      btnLoading: false,
      isLoading: false,
      AllPositions: [],
      allPositionEmployees: [],
      // selected: [],
      // selectAll: false,
      employee_id: [],

    }
  },
  methods: {
    //
    // select() {
    //   this.selected = [];
    //   if (!this.selectAll) {
    //     for (let i in this.allPositionEmployees) {
    //       this.selected.push(this.allPositionEmployees[i].id);
    //     }
    //   }
    // },

    changeStatus(item) {
      if (item.active === false) {
        const id = this.$route.params.position_id;
        const result = this.employee_id.concat(item.id)
        this.$axios.post(`company/position/${id}/employees/deactive`, {employee_id: result}).then((res) => {
          if (res.data.code === 200) {
            setTimeout(() => {
              this.$emit('close');
              this.$emit('success');
              this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
            }, 300)
            this.btnLoading = false;
            this.isLoading = false;
            // this.selected = [];
            // this.selectAll = false;
            this.fetchAllPositionEmployee();
          }
        })
      } else {
        const id = this.$route.params.position_id;
        const result = this.employee_id.concat(item.id)
        this.$axios.post(`company/position/${id}/employees/active`, {employee_id: result}).then((res) => {
          if (res.data.code === 200) {
            setTimeout(() => {
              this.$emit('close');
              this.$emit('success');
              this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
            }, 300)
            this.btnLoading = false;
            this.isLoading = false;
            // this.selected = [];
            // this.selectAll = false;
            this.fetchAllPositionEmployee();
          }
        })
      }
    },

    fetchAllPosition() {
      this.$axios.get(`company/all-position`).then((res) => {
        this.AllPositions = res.data.data;
      }).catch(() => {
      })
    },

    onCreate() {
      this.$store.commit("modalAdd_State", true);
    },

    fetchAllPositionEmployee() {
      const id = this.$route.params.position_id;
      this.$axios.get(`company/position/${id}/employees`).then((res) => {
        this.allPositionEmployees = res.data.data.data;
      }).catch(() => {
      })
    }

  },

  computed: {
    filterOldPosition() {
      const position_id = this.$route.params.position_id;
      return this.AllPositions.filter(function (position) {
        return position.id == position_id;
      });
    },
  },


  created() {
    this.fetchAllPosition();
    this.fetchAllPositionEmployee();
  }
}
</script>
<style scoped lang="scss">
.OldPosition {
  text-transform: capitalize;

  .CountEmployee {
    font-weight: normal;
    font-size: 18px;
  }
}

.btn-show-action-selected {
  position: absolute;
  right: 200px;

  .btn-remove {
    border: none;
    outline: none;
    height: 40px;
    transition: all .125s ease-in;
    background-color: $main-btn-color-remove;
    color: #FFFFFF;
    text-transform: capitalize;
    box-shadow: 0 2px 4px 1px rgb(0 0 0 / 12%);
  }

  .btn-active {
    border: none;
    outline: none;
    height: 40px;
    transition: all .125s ease-in;
    background-color: rgba(46, 204, 113, 0.4);
    color: #FFFFFF;
    text-transform: capitalize;
    box-shadow: 0 2px 4px 1px rgb(0 0 0 / 12%);
  }
}


.active-position {
  display: flex;
  align-items: center;
  color: #70F7BD;

  i {
    color: #70F7BD;
    font-size: 8px;
    padding: 0 5px;
  }
}

.deactive-position {
  display: flex;
  align-items: center;
  color: #EB7070;
  font-weight: 500;

  i {
    color: #EB7070;
    font-size: 8px;
    padding: 0 5px;
  }
}

.image-table {
  width: 50px;
  height: 50px;
  background-color: #eeeeee;

  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  img {
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 50%;
  }

}

.demo-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #eeeeee;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    font-size: 24px;
    color: #ffffff;
  }
}

</style>
